import Swiper, { Navigation, Pagination } from 'swiper';

export class SwiperSlide {

    constructor(){
        Swiper.use([Pagination]);
    }

    initialize(){
        this._events();
    }

    _events(){

         new Swiper(".swiper", {
            modules: [Pagination],
            pagination: {
                el: '.swiper-pagination',
                clickable: true
              },
          });
        }

}
