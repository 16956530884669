import LazyLoad from "vanilla-lazyload";

export class LazyLoads {
    
    constructor(){
    }

    initialize(){
      new LazyLoad({ elements_selector: '.lazy', });
    }

}
