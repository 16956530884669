export class SideMenu {
    
    constructor(){
        this.openMenuBtn = document.querySelector(".js-navBtnOpen");
        this.closeMenuBtn = document.querySelector(".js-navBtnClose")
        this.sideMenuOpen = document.querySelector(".js-navList");
    }

    initialize(){
        this._events();
    }

    _events(){
        this.openMenuBtn.addEventListener("click", ()=> this._toggleNav());
        this.closeMenuBtn.addEventListener("click", ()=> this._toggleNav());   
    }

    _toggleNav(){ 
        this.sideMenuOpen.classList.toggle("c-navigation__list--active");
    }

  
}
