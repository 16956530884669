export class UserLogin {
    
    constructor(){
        this.openLogin = document.querySelector(".js-navList__login");
        this.userLoginInfo= [];
    }

    initialize(){
        this._render().then(() => {
            this.el = document.querySelector(".js-modal");
            this.overlay = this.el.querySelector(".js-modal__overlay");
            this.form = this.el.querySelector(".js-form");
            this.email = this.el.querySelector(".js-form__email");
            this.pw = this.el.querySelector(".js-form__pw");
            this.btnLogin = this.el.querySelector(".js-form__btnLogin");
            this.btnClose = this.el.querySelector(".js-form__btnClose");
            this.errorMessage = this.el.querySelector(".js-form__errorMessage");
       
            this.__events();
        })
    }

    __events(){

        this.openLogin.addEventListener("click", ()=>{
            this.__toggleModal();
            this.form.reset();
            this.errorMessage.innerText = ""
        })
        this.btnClose.addEventListener("click", ()=>{
            this.__toggleModal();
        })

        this.btnLogin.addEventListener("click", ()=>{
           this.__userLogIn();
        })

        this.overlay.addEventListener("click", ()=>{
            this.__toggleModal();
        })
    }
  
    __userLogIn(){
        if(!this.email.checkValidity() && !this.pw.checkValidity()){
            this.errorMessage.innerText = "That wasn't correct. Try again?";
            this.form.reset();
        }else if (!this.email.checkValidity()){
            this.errorMessage.innerText = "Email was not correct. Please try again.";
            this.form.reset();
        } else if(!this.pw.checkValidity()){
            this.errorMessage.innerText = "Password was not correct. Please try again.";
            this.form.reset();
        } else {
            let userDetails = {
                email: this.email.value,
                password: this.pw.value
              };
              this.userLoginInfo.push(userDetails);
              localStorage.setItem("AllRegisteredUserInfo", JSON.stringify(this.userLoginInfo));
              this.__toggleModal();
            }
    }

    __toggleModal(){
        this.el.classList.toggle("c-modal--visible");
    }


    _render() {
        return Promise.resolve(document.body.insertAdjacentHTML('beforeend', this._template));
    }

    get _template() {
        return `<div class="c-modal js-modal">
        <div class="c-modal__overlay js-modal__overlay"></div>
          <div class="c-modal__box js-modalBox">
              <form class="e-form js-form">
                <h2 class="e-h2">Login</h2>
                <div class="e-form__box">
                  <label class="e-form__label" for="email"><b>Email</b></label>
                  <input class="e-form__input js-form__email" pattern="[A-Za-z0-9._+-]+@[A-Za-z0-9 -]+\.[a-z]{2,}" id="email" title="Must be a valid email address" type="email"  placeholder="Enter Email" required>
                </div>
                <div class="e-form__box">
                  <label class="e-form__label" for="psw"><b>Password</b></label>
                  <input class="e-form__input js-form__pw" type="password" placeholder="Enter Password" name="psw" autocomplete="on" pattern="[a-zA-Z0-9]{8,}" title="Must be at least 8 characters" required>        
                </div>
                <div class="e-form__box js-form__errorMessage"></div>
                <div class="e-form__buttons">
                  <button type="button" class="btn btn--modal btn--darkGreen js-form__btnLogin">Login</button> 
                  <button type="button" class="btn btn--modal btn--white js-form__btnClose">Close</button> 
                </div>
              </form>
            </div>
          </div>`;
    }
}
