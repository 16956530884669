import Header from "headroom.js";

export class MoveHeader {
    
    constructor(){
        this.header = document.querySelector(".js-header");
    }

    initialize(){
        // construct an instance of Headroom, passing the element
        const headerJs  = new Header(this.header, {
            tolerance:{down: 20, up:25}
        });
        // initialise
        headerJs.init();
    }   

}

