import "./style.scss"
import {SideMenu} from "./js/sideMenu";
import {SwiperSlide} from "./js/swiper";
import {FooterDropdown} from "./js/footerDropdown";
import { LazyLoads } from "./js/lazyLoad";
import { UserLogin } from "./js/userLogin";
import cookieAccepted from 'js-cookie';
import { MoveHeader } from "./js/header";

    const sideMenu = new SideMenu();
    sideMenu.initialize();
    
    const swiperSlide = new SwiperSlide();
    swiperSlide.initialize();

    const footerDropdown = new FooterDropdown();
    footerDropdown.initialize();

    const lazyLoad = new LazyLoads();
    lazyLoad.initialize();

    const userLogin = new UserLogin();
    userLogin.initialize();

    const header = new MoveHeader();
    header.initialize();

    if(typeof cookieAccepted.get('name') === 'undefined'){
        import("./js/cookiesBanner").then(({ CookieBanner }) => {
            const cookieBanner = new CookieBanner();
            cookieBanner.initialize();
      })
    }


