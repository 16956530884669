export class FooterDropdown {
    
    constructor(){
        this.btns = document.querySelectorAll(".js-footer__btn");
        this.links = document.querySelectorAll(".js-footer__link");
        this.blocks = document.querySelectorAll(".js-footer__block");
    }

    initialize(){
        this._events();
    }

    _events(){
        this.btns.forEach(element =>{
            element.addEventListener("click", ()=> {
                this._toggleMenu(element); 
            })
            
        })
    }

    _toggleMenu(element){
           const parentElement = element.closest(".js-footer__block");   
            
            this.blocks.forEach(el =>{
                if(el===parentElement){
                    el.classList.toggle("c-footer__contentBlock--active")
                } else {
                    el.classList.remove("c-footer__contentBlock--active")                 
                }          
            })
        }

  
}
